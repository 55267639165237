import React, { useState, useEffect } from "react"
import tw from "tailwind-styled-components"
import { Link } from "gatsby"

import Header from "./Header"
import Wrapper from "./Wrapper"
import { useStaticQuery, graphql } from "gatsby"

const Container = tw.div`
  flex 
  gap-3 
  pb-2 
  hover:underline
`
export default function Layout({ pathname, layoutData, children }) {
  const [menuItem, setMenuItem] = useState([])
  const [services, setServices] = useState([])
  const [company, setCompany] = useState([])
  const [supports, setSupport] = useState([])

  const data = useStaticQuery(graphql`
    query {
      allWpMenu {
        nodes {
          slug
          menuItems {
            nodes {
              label
              childItems {
                nodes {
                  label
                  path
                  childItems {
                    nodes {
                      label
                      path
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const [allMenu] = data?.allWpMenu?.nodes
    const [menuItems] = allMenu?.menuItems?.nodes
      .filter(item => item.label === "Footer")
      .map(res => {
        return res.childItems.nodes
      })

    setMenuItem(menuItems)
  }, [data])

  useEffect(() => {
    const [serviceList] = menuItem.filter(item => item.label === "SERVICES")
    setServices(serviceList?.childItems?.nodes)
    const [companyList] = menuItem.filter(item => item.label === "COMPANY")
    setCompany(companyList?.childItems?.nodes)
    const [support] = menuItem.filter(item => item.label === "HELP & SUPPORT")
    setSupport(support?.childItems?.nodes)
  }, [menuItem])

  const getIcon = service => {
    const [matchIcon] =
      layoutData?.services?.filter(
        item => item.serviceName.toLowerCase() === service.toLowerCase()
      ) || []
    return matchIcon?.serviceIcon?.sourceUrl
  }

  return (
    <div className="bg-primary">
      <Wrapper>
        <Header pathname={pathname} layoutData={layoutData} />
        {children}
        <div className="flex flex-col justify-between gap-16">
          <div className="flex flex-row justify-between">
            <div className="w-12 md:w-16 lg:w-24">
              <img src="/esntl.svg" alt="#esntl" />
            </div>
            <div className="flex flex-col">
              <div className="flex gap-3">
                <div className="w-1/4"></div>
                <div className="w-3/4">
                  <h3 className="mb-6 text-xs font-bold tracking-widest opacity-50">
                    SERVICES
                  </h3>
                </div>
              </div>
              {services?.map((item, index) => {
                return (
                  <Container key={index}>
                    <img
                      className="aspect-square w-1/4"
                      src={getIcon(item.label)}
                      alt="#Icon"
                    />
                    <Link className="w-3/4 text-xs md:text-base" to={item.path}>
                      <h3>{item.label}</h3>
                    </Link>
                  </Container>
                )
              })}
            </div>
            <div>
              <h3 className="mb-6 text-xs font-bold tracking-widest opacity-50">
                COMPANY
              </h3>
              {company?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="pb-2 text-xs hover:underline md:text-base"
                  >
                    <Link to={item.path}>
                      <h3>{item.label}</h3>
                    </Link>
                  </div>
                )
              })}
            </div>
            <div>
              <h3 className="mb-6 text-xs font-bold tracking-widest opacity-50">
                HELP & SUPPORT
              </h3>
              {supports?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="pb-2 text-xs hover:underline xs:text-xs md:text-base"
                  >
                    <Link to={item.path}>
                      <h3>{item.label}</h3>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="pb-8">
            <div className="text-xs font-medium">
              {layoutData?.policy?.map((item, index) => {
                return (
                  <Link
                    className={`px-2 first:pl-0 last:pr-0 hover:underline ${
                      index + 1 !== layoutData.policy.length ? "border-r" : ""
                    }`}
                    key={index}
                    to={item.policyName.url}
                  >
                    <p className="inline">{item.policyName.title}</p>
                  </Link>
                )
              })}
            </div>
            <p className="pt-4 text-xs">{layoutData?.termscontent}</p>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
