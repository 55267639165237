import React from "react"

import "./src/styles/global.css"
import Layout from "./src/components/Layout"

export function wrapPageElement({ element, props }) {
  const { acfLayout } = props?.data?.wpPage || {}
  return (
    <>
      {acfLayout && (
        <Layout layoutData={acfLayout} pathname={props.location.pathname}>
          {element}
        </Layout>
      )}
    </>
  )
}
