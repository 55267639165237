import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import tw from "tailwind-styled-components"
import { useStaticQuery, graphql } from "gatsby"

const Container = tw.div`
  flex 
  justify-between 
  flex-row 
  gap-2 
  text-center 
  py-2 
  items-center 
  font-normal 
  text-black 
  min-h-[80px]
  font-BrownRegular
  `

export default function Header({ pathname = " ", layoutData }) {
  const [menuItem, setMenuItem] = useState([])
  const data = useStaticQuery(graphql`
    query {
      allWpMenu {
        nodes {
          slug
          menuItems {
            nodes {
              label
              childItems {
                nodes {
                  label
                  path
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const [allMenu] = data.allWpMenu?.nodes

    const [menuItems] = allMenu.menuItems?.nodes
      .filter(item => item.label === "Header")
      .map(res => {
        return res.childItems.nodes
      })

    setMenuItem(menuItems)
  }, [data])

  return (
    <Container>
      <div className="flex items-center gap-5 text-base md:gap-6 lg:gap-12">
        <Link to="/">
          <img className="w-20 md:max-w-none" src="/esntl.svg" alt="esntl" />
        </Link>
        <div className="flex items-center gap-2 text-xs xs:gap-4 xs:text-base sm:gap-6 lg:gap-9">
          {menuItem.map((item, index) => {
            return (
              <Link
                key={index}
                className={`hover:text-gray ${
                  (pathname.startsWith(item.path) &&
                    item.label !== "Partners") ||
                  pathname === item.path
                    ? "border-b-2"
                    : ""
                }`}
                to={item.path}
              >
                {item.label}
              </Link>
            )
          })}
        </div>
      </div>
      {layoutData?.button && (
        <div className="hidden rounded-full bg-orange md:flex">
          <button className="py-2 px-3 text-base text-white">
            {layoutData?.button}
          </button>
        </div>
      )}
    </Container>
  )
}
